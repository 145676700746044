import React from "react";
import Layout from '../components/layout';
import { graphql } from "gatsby";
import ImageLoader from "../components/image-loader";
import pagesStyles from "../styles/pages.module.css"

export default function Contact({ data }) {
  return (
    <div>
      <Layout>
        <ImageLoader data={data}>
          <h1 className={pagesStyles.mbNormal}>{data.allMarkdownRemark.edges[0].node.frontmatter.title}</h1> 
          <div className={pagesStyles.mbNormal}>{formatText(data)}</div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2707.295022061677!2d8.64003471537615!3d47.269487119198146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aaf6f9bd32959%3A0xacfe8bdb0828a273!2sH%C3%BCniweg%2012%2C%208706%20Meilen!5e0!3m2!1sde!2sch!4v1600156139447!5m2!1sde!2sch" width="100%" height="450" style={{border: '0'}}></iframe>
        </ImageLoader>
      </Layout>
    </div>
  )
}

function formatText(data) {
  let lines = data.allMarkdownRemark.edges[0].node.rawMarkdownBody.split('\n');
  let formatted = []
  let count = 0;
  for (let line of lines) {
    count++;
    formatted.push(<p key={count}>{line}</p>)
  }
  return formatted;
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {page: {eq: "contact"}}}) {
      edges {
        node {
          frontmatter {
            title
            page
            images {
              image
            }
            imagePosition
            rowCount
          }
          rawMarkdownBody
        }
      }
    }
  } 
`
